$inputBorderColor: (unfocus:#AAAAAA, focus:#666666, changed: #00FF00, error:#FF0000);
$inputBackColor: #EEEEEE;
$inputCorrection : '10px';

.inputBox{
	width: 100%;

	display: grid;
	grid-template-columns: 10px 1fr 10px;
	grid-template-rows: 34px;
	grid-template-areas:
	". input .";


	&__label{
		grid-area: label;

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		padding-right: 5px;
		padding-left: 5px;

		font-size: 12pt;
		user-select: none;
	}
	&__input{
		grid-area: input;
		width: calc(100% - 22px);
		height: 30px;
		border: 1px solid map-get($inputBorderColor, unfocus);
		border-radius: 8px 8px 8px 8px;

		font-size: 12pt;
		padding-left: 10px;
		padding-right: 10px;

		&--changed{
			font-weight: bold;
			border-color: map-get($inputBorderColor, changed);
		}
		&--error{
			border-color: map-get($inputBorderColor, error);
		}
		&--disabled{
			background-color: $inputBackColor;
		}
		&:focus{
			border: 1px solid map-get($inputBorderColor, focus);
			border-radius: 8px 8px 8px 8px;
			outline: none;
		}

	}
	&__helper{
		grid-area: helper;

		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		font-size: 10pt;
		padding-left: 5px;
		padding-right: 5px;
		color: map-get($inputBorderColor, error);
		user-select: none;
	}
	&--label{
		grid-template-columns: 10px 1fr 10px;
		grid-template-rows: 30px 34px;
		grid-template-areas:
		". label ."
		". input .";

		&--error{
			grid-template-columns: 10px 1fr 10px;
			grid-template-rows: 30px 34px 4px 15px 4px;
			grid-template-areas:
			". label ."
			". input ."
			". . ."
			". helper ."
			". . .";
		}
	}
	&--error{
		grid-template-columns: 10px 1fr 10px;
		grid-template-rows: 34px 4px 15px 4px;
		grid-template-areas:
		". input ."
		". . ."
		". helper ."
		". . .";
	}
	&--password, &--file, &--search, &--percent{

		&__inputDiv{
			grid-area: input;

			display: grid;
			grid-template-columns: 1fr 40px;
			grid-template-rows: 1fr;
			grid-template-areas:
			"input_box actions_box";

			width: 100%;


			&__input{
				grid-area: input_box;

				border-radius: 8px 0px 0px 8px;

				&:focus{
					border: 1px solid map-get($inputBorderColor, focus);
					border-radius: 8px 0px 0px 8px;
					outline: none;
				}
			}
			&__actionsBox{
				grid-area: actions_box;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				border-top: 1px solid map-get($inputBorderColor, unfocus);
				border-bottom: 1px solid map-get($inputBorderColor, unfocus);
				border-right: 1px solid map-get($inputBorderColor, unfocus);
				border-radius: 0px 8px 8px 0px;

				background-color: #FFFFFF;

				user-select: none;
				cursor: pointer;

				font-size: 12pt;
				color: #444444;


				&--disabled{
					background-color: rgba(0,0,0,0);
				}
				&:hover{
					color: #000000;
					font-weight: bold;
				}
			}
		}
	}
}
.hidden{
	display:none;
}