@import "../utils/variables";
.footer{
  display: flex;
  min-height: $footer_height;
  max-height: $footer_height;
  background-color: #222222;
  font-size: 9pt;
  color: #FFFFFF;
  user-select: none;
  &-box{
    display: flex;
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;

    &--first{
      justify-content: flex-start;
      &-icon{
        margin-right: 10px;
        font-size: 12pt;
        color: #FF0000;
        cursor: pointer;
        &--connected{
          margin-right: 10px;
          font-size: 12pt;
          color: #FFFFFF;
          cursor: pointer;
        }
      }
    }
    &--last{
      justify-content: flex-end;
    }
    > a{
      color: #FFFFFF;
      text-decoration: none;
    }
  }
}
