@import "../utils/mixins";

.home{
  @include page;
  display: flex;
  justify-content: center;
  align-items: center;
  &-actions{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    &-item{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      min-width: 250px;
      padding: 40px;
      margin: 20px;

      border-radius: 30px;

      box-shadow: 0px 0px 17px 1px rgba(0,0,0,0.20);
      &-icon{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      &-title{
        flex: 1;
        width: 100%;
        margin-top: 15px;
        margin-bottom: 5px;
        text-align: center;
        font-weight: bold;
        font-size: 23px;
      }
      &-description{
        flex: 1;
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: rgba(74,74,74,1);
      }
      &-actions{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 25px;
        &-button{
          background-color: #000000;
          color: #FFFFFF;
          &:hover{
            font-weight: normal;
          }
        }
      }
    }
  }
}
