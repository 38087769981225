@import "../variables";
@mixin page {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #FFFFFF;
  overflow-y: hidden;
}
@mixin content {
  display: flex;
  flex: 1;
}
@mixin content-list-view {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "list";
}
@mixin content-split-view {
  display: grid;
  height: calc(100% - 110px);
  grid-template-columns: 400px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "item list";
}
@mixin content-item-view {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "item";
}
@mixin content-item {
  grid-area: item;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;
  height: calc(100% - 40px);
  padding: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 17px 1px rgba(0,0,0,0.10);
}
@mixin content-list {
  grid-area: list;
  padding: 20px;
  height: calc(100% - 40px);
  background-color: #EFEFEF;
  overflow-x: hidden;
}
