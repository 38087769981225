@import "../utils/variables";
$popup-width: 250px;
.mainNavigation{
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  height: $heider_height;

  padding-left: 20px;
  padding-right: 20px;

  background-color: $footer_backgroundcolor;
  color: #FFFFFF;
  user-select: none;

  &-logo{
    height: calc(#{$heider_height} - 30px);
    margin-left: 50px;
    margin-right: 50px;
    cursor: pointer;
    &-img{
      width: 100%;
      height: 100%;
    }
  }
  &-appName{
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    cursor: pointer;
  }
  &-nav{
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    &-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 9pt;
      letter-spacing: 1px;
      cursor: pointer;
      &-icon{
        width: 18px;
        height: 18px;
      }
      &-text{
        margin-left: 8px;
      }
      &:hover, &--active{
        color: #e30613;
      }
    }

  }
  &-actions{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    &-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      padding-left: 10px;
      padding-right: 10px;

      cursor: pointer;
      &-icon{
        width: 18px;
        height: 18px;
      }
      &-text{
        margin-left: 8px;
        font-size: 11pt;
      }
      &:hover{
        color: #e30613;
      }
    }

  }
  &-popup{
    position: fixed;
    top: $heider_height;
    left: -10000px;
    width: $popup-width;
    z-index: 200;
    color: #000000;
    box-shadow: 0px 0px 17px 1px rgba(0,0,0,0.20);
    border-top-width: 0px;
    background-color: #FFFFFF;

    &-login{
      left: calc(100% - #{$popup-width});
      padding: 20px;
      padding-bottom: 40px;
    }
    &-item{
      display: flex;
      flex-direction: row;
      padding: 20px;
      cursor: pointer;
      &-text{
        margin-left: 15px;
      }
      &-icon{
        font-size: 13pt;
      }
      &:hover{
        background-color: #EEEEEE;
      }
    }

    &--showed{
      left: calc(100% - #{$popup-width});
    }
    &-lg{
      left: calc(100% - 380px);
      width: 380px;
    }
  }
  &-modal{
    padding-bottom: 50px;
    &-button{
      height: 25px;
      margin-top: 20px;
      margin-bottom: 10px;
      padding: 5px;
    }
    &--sm{
      width: 20%;
    }
    &--md{
      width: 33%;
    }
    &--lg{
      width: 50%;
    }
    &--xl{
      width: 80%;
    }
  }
}
