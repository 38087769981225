.react-table{
    height: 100%;
    display: grid;
    grid-template-rows:  50px 1fr 50px;
    grid-template-columns: 1fr;
    grid-template-areas: 
        "header" 
        "data"
        "footer";
    width: 100%;
    height: 100%;

    user-select: none;
    
    &-header{
        grid-area: header;
        display: flex;
        background-color: #000000;
        color: #FFFFFF;
        border-radius: 24px 24px 0px 0px;
        &-title{
            flex: 3;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 20px;
        }
        &-search{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    &-data{
        grid-area: data;
        width: 100%;
        height: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        background-color: #EEEEEE;
        &-grid{
            height: 100%;
            display: grid;
            overflow-y: scroll;
            &-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 100%;
                max-height: 80px;
                cursor: pointer;
                white-space: nowrap;
                background-color: #FFFFFF;
                > div{
                    padding: 5px;
                }
                &:hover{
                    font-weight: bold;
                }
                &--even{
                    background-color: #DDDDDD;
                }
            }
            &-header{
                position: sticky;
                top: 0;
                max-height: 40px;
                padding: 0px;
                background-color: #000000;
                font-weight: bold;
                cursor: pointer;
                color: #FFFFFF;
                &-box{
                    display: flex;
                    justify-content: space-between!important;
                    padding: 10px;
                    &-sorteIcon{
                        margin-left: 20px;
                    }
                }
            }
            &-imageBox{
                height: 40px;
                display: flex;
                align-items: center;
                img{
                    height: 80%;
                }
            }
            &-suitabilityBox{
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    height: 50%;
                }
            }
        }
    }
    &-footer{
        grid-area: footer;
        width: 100%;
        height: 100%;
        display: flex;
        background-color: #000000;
        color: #FFFFFF;
        border-radius: 0px 0px 24px 24px;
        &-left{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 20px;
            padding-right: 20px;
        }
        &-center{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 10px;
            padding-right: 10px;
        }
        &-right{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            padding-left: 20px;
            padding-right: 20px;
        }
        &-itemShowed{
            width: 120px;
        }
        &-infos{
            
        }
        .select{
            color: #000000;
        }
        .select__actions{
            background-color: #FFFFFF;
            border-radius: 0px 8px 8px 0px;
        }
        .inputBox__input{
            border-radius: 8px 0px 0px 8px;
        }
    }
    &-pagination{
        display: grid;
        grid-template-columns: 20px 90px 30px 90px 20px;
        &-previous{
            display: flex;
            align-items: center;
            justify-content:  flex-end;
            div{
                margin-left: 1px;
                margin-right: 1px;
                cursor: pointer;
            }
        }
        &-current{
            display: flex;
            align-items: center;
            justify-content:  center;
            margin-left: 10px;
            margin-right: 10px;
            color:red;
        }
        &-next{
            display: flex;
            align-items: center;
            justify-content:  flex-start;
            div{
                margin-left: 1px;
                margin-right: 1px;
                cursor: pointer;
            }
        }

        &-action{
            margin-left: 10px;
            margin-right: 10px;
            cursor: pointer;
        }
    }
}