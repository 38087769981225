@import "../../utils/mixins";
.subnav{
  @include subnav;
  &-groups{
    display: flex;
    flex: 1;
    &-group{
      @include subnav-group;
      &-title{
        @include subnav-group-title;
      }
      &-items{
        @include subnav-items;
        &-item{
          @include subnav-item;
          &-icon{
            @include subnav-item-icon;
            &-element{
              @include subnav-group-item-icon-element;
            }
          }
          &-title{
            @include subnav-item-title;
          }
        }
      }
    }
  }
  &-views{
    @include subnav-views;
    &-group{
      @include subnav-views-group;
      &-item{
        @include subnav-views-item;
        &-icon{
          @include subnav-item-icon;
          &-element{
            @include subnav-group-item-icon-element;
          }
        }
        &-title{
          @include subnav-item-title;
        }
      }
    }
  }
}
