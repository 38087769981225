@import "../variables";
@mixin subnav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: $subnav_height;
  box-shadow: 0px 0px 17px 1px rgba(0,0,0,0.10);
  user-select: none;
  z-index: 100;
}
@mixin subnav-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-right: 2px solid #EFEFEF;
}
@mixin subnav-group-title {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 10px;
  color: #888888;
}
@mixin subnav-items {
  display: flex;
  height: 100%;
}
@mixin subnav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 100px;
  cursor: pointer;
  &--disable{
    color: #EEEEEE;
    &:hover{
      background-color: rgba(0,0,0,0);
    }
  }
  &--active{
    background-color: #EEEEEE;
  }
  &:hover{
    background-color: #EEEEEE;
  }
}
@mixin subnav-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@mixin subnav-group-item-icon-element {
  padding-top: 20px;
  padding-bottom: 2px;
  font-size: 15px;
}
@mixin subnav-item-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  font-size: 11px;
}
@mixin subnav-views {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-left: 2px solid #EFEFEF;
}
@mixin subnav-views-group {
  @include subnav-group;
  flex-direction: row;
}
@mixin subnav-views-item {
  @include subnav-item;
}
