@import "../utils/mixins";

.error_404{
  @include page;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &-box{
    padding: 50px;
    &-title{
      font-weight: bold;
      font-size: 30px;
      margin-bottom: 30px;
    }
    &-description{

    }
  }
}
