$inputBorderColor: (unfocus:#AAAAAA, focus:#666666, changed: #00FF00, error:#FF0000);
.select{
	width: 100%;
	height: 34px;
	display: grid;
	grid-template-columns: 1fr 40px 10px;
	grid-template-rows: 1fr;
	grid-template-areas:
	"input actions .";
	overflow: visible;
	.input{
		grid-area: input;
		overflow: hidden;
		.inputBox{
			width: calc(100% + 20px)!important;
		}
	}
	.actions{
		width: 100%;
		height: 100%;
		max-height: 32px;
		grid-area: actions;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		background-color: #FFFFFF;
		border: 1px solid #AAAAAA;
		border-left-width: 0px;
		border-radius: 0px 8px 8px 0px;

		cursor: pointer;

		user-select: none;

		&--label{
			padding-top: 15px;
		}
	}
	.list{
		display: none;
	}

	&--listShowed{
		grid-template-rows: 35px 1fr;
		grid-template-areas:
		"input actions"
		"list .";
		.list{
			display: grid;
			grid-area: list;
			padding-left: 10px;
			padding-right: 20px;
			z-index: 2000;
			.box{
				width: calc(100% + 20px);
				height: 100%;

				background-color: #FFFFFF;
				border: 1px solid map-get($inputBorderColor, unfocus);
				.item{
					height: 35px;
					padding-left: 10px;
					padding-right: 10px;

					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					border-bottom: 1px solid map-get($inputBorderColor, unfocus);
					cursor: pointer;
					&:last-child{
						border-bottom-width: 0px;
					}
					&:hover{
						font-weight: bold;
					}
				}
			}

		}
	}
	&--listShowedUp{
		.list{
			position: relative;
			top: calc(-100% - 37px);
		}
	}
}