@import "../utils/mixins";

.test{
  @include page;
  flex-direction: row;
  padding: 20px;
  .column{
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
  }
  .button{
    width: 300px;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #b4b4b4;
    color: #FFFFFF;

    cursor: pointer;
  }
}
