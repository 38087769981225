@import "../utils/mixins";

.article{
  @include page;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    "subnav"
    "content";
  &-subnav{
    grid-area: subnav;
    display: flex;
    background-color: #f4f4f8;
    user-select: none;
    &-button{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      padding-left: 14px;
      padding-right: 14px;
      background-color: rgba(155,155,155,1);
      border-radius: 8px;
      font-weight: bold;
      color: #FFFFFF;
      cursor: pointer;
      &:hover{
        background-color: #e30613;
      }
    }
    &-icon{
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }
    &-return-box{
      flex: 1;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      cursor: pointer;
    }
    &-left-box{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
    &-center-box{
      flex: 6;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-right-box{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-mainWrapper{
    display: grid;
    overflow: auto;
    padding-bottom: 50px;
    &-headerBox{
      display: grid;
      grid-template-columns: 30% 1fr 30%;
      margin-top: 50px;
      &-leftBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &-centerBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &-rightBox{
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .table{
        width: calc(100% - 100px);
        margin-left: 50px;
        margin-right: 50px;
        border: 1px solid rgba(216,216,216,1);
        border-radius: 20px;
        .row{
          display: flex;
          height: 40px;
          padding-left: 20px;
          padding-right: 20px;
          .label{
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: bold;
          }
          .value{
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
          &:nth-child(even){
            background-color: rgba(216,216,216,1);
          }
          &:last-child{
            border-radius: 0px 00px 20px 20px;
          }
        }
        
      }
      .imgBox{

        img{
          height: 120px;
        }
      }
    }
    &-articleBox{
      display: grid;
      grid-template-columns: 30% 1fr 30%;
      margin-top: 50px;
     

      &-leftBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &-centerBox{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
      }
      &-rightBox{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .table{
        width: calc(100% - 100px);
        margin-top: -48px;
        margin-left: 50px;
        margin-right: 50px;
        border: 1px solid rgba(216,216,216,1);
        border-radius: 20px;
        .row{
          display: flex;
          height: 40px;
          padding-left: 20px;
          padding-right: 20px;
          .label{
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: bold;
          }
          .value{
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
          &:nth-child(even){
            background-color: rgba(216,216,216,1);
          }
          &:last-child{
            border-radius: 0px 00px 20px 20px;
          }
        }
      }
    }
  }
}
