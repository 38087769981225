@import "../utils/mixins";

.materialsSelector{
  @include page;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    "subnav"
    "content";

  &-subnav{
    grid-area: subnav;
    display: flex;
    background-color: #f4f4f8;
    user-select: none;
    &-button{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      padding-left: 14px;
      padding-right: 14px;
      background-color: rgba(155,155,155,1);
      border-radius: 8px;
      font-weight: bold;
      color: #FFFFFF;
      cursor: pointer;
      &:hover{
        background-color: #e30613;
      }
    }
    &-icon{
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }
    &-return-box{
      flex: 1;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      cursor: pointer;
    }
    &-left-box{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
    &-center-box{
      flex: 6;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-right-box{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-content{
    grid-area: content;
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 130px 200px 1fr;
    grid-template-areas:
      "title"
      "filters"
      "list";
    overflow-y: hidden;
    &-title{
      grid-area: title;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: rgba(155,155,155,1);
      user-select: none;
      &-main{
        font-size: 30px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      &-sub{
        font-size: 15px;
      }
    }
    &-filters{
      grid-area: filters;
      display: flex;
      align-items: stretch;
      justify-content: center;
      padding-left: 20%;
      padding-right: 20%;
      padding-top: 20px;
      padding-bottom: 20px;
      user-select: none;
      &-item{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 15px;
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0px 0px 17px 1px rgba(0,0,0,0.20);

        &-title{
          font-weight: bold;
          padding: 10px;
        }
        &-input{
          width: 100%;
        }
      }
    }
    &-list{
      display: flex;
      align-items: center;
      justify-content: center;
      grid-area: list;
      padding-left: 15%;
      padding-right: 15%;
      padding-bottom: 15px;
      overflow-y: scroll;
    }
  }
}
.toolsSelector{
  @include page;
  background-color: #EEEEEE;
  display: grid;
  height: 100%;
  grid-template-columns: 350px 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "panel list";

  &-panel{
    grid-area: panel;
    max-height: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    overflow-y: hidden;
    &-title{
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 15px;
      &-icon{
        width: 38px;
        height: 34px;
      }
      &-text{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        font-size: 25px;
        font-weight: bold;
      }
    }
    &-filters{
      flex: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 15px;
      }
      &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
      &-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;
        margin-bottom: 15px;
        box-shadow: 0px 0px 17px 1px rgba(0,0,0,0.20);
        border-radius: 5px;
        &-title{
          margin-bottom: 15px;
          font-size: 12px;
          font-weight: bold;
        }
        &-input{

        }
      }
    }
    &-actions{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-button{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        padding-left: 14px;
        padding-right: 14px;
        margin-top: 5px;
        margin-bottom: 5px;
        background-color: rgba(155,155,155,1);
        border-radius: 8px;
        font-weight: bold;
        color: #FFFFFF;
        cursor: pointer;
        &:hover{
          background-color: #e30613;
        }
      }
    }
  }
  &-list{
    grid-area: list;
    padding: 40px;
    overflow-y: hidden;
  }
}
.item{
  @include page;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    "subnav"
    "content";
  &-subnav{
    grid-area: subnav;
    display: flex;
    background-color: #f4f4f8;
    user-select: none;
    &-button{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38px;
      padding-left: 14px;
      padding-right: 14px;
      background-color: rgba(155,155,155,1);
      border-radius: 8px;
      font-weight: bold;
      color: #FFFFFF;
      cursor: pointer;
      &:hover{
        background-color: #e30613;
      }
    }
    &-icon{
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }
    &-return-box{
      flex: 1;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      cursor: pointer;
    }
    &-left-box{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
    }
    &-center-box{
      flex: 6;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-right-box{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-content{
    display: grid;
    height: 100%;
    grid-template-columns: 250px 1fr 1fr 250px;
    grid-template-rows: 350px 1fr;
    grid-template-areas:
      ". header header ."
      "artciles_data artciles_data speed_data speed_data";
    &-header{
      grid-area: header;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      &-headerNo{
        display: flex;
        flex-direction: column;
        padding: 15px;

        &-title{
          text-align: center;
          font-size: 12px;
          font-weight: bold;
        }
        &-value{
          text-align: center;
          margin-top: 10px;
          font-size: 30px;
          font-weight: bold;
        }
      }
      &-category{
        display: flex;
        flex-direction: column;
        padding: 15px;

        &-title{
          text-align: center;
          font-size: 12px;
          font-weight: bold;
        }
        &-value{
          text-align: center;
          margin-top: 10px;
          font-size: 30px;
          font-weight: bold;
        }
      }
      &-type{
        display: flex;
        flex-direction: column;
        padding: 30px;
        &-title{
          text-align: center;
          font-size: 12px;
          font-weight: bold;
        }
        &-value{
          text-align: center;
          margin-top: 10px;
          font-size: 30px;
          font-weight: bold;
        }
      }
      &-img{
        padding: 0px;
        img{
          height: 80px;
        }
      }
      &-diagram{
        padding: 30px;
        img{
          height: 100px;
        }
      }
      &-features{
        &-row{
          display: flex;
          img{
            height: 50px;
            margin: 3px;
          }
          &:last-child{
            margin-top: 30px;
          }
        }

      }
      &-left{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &-center{
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &-right{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    &-articlesData{
      grid-area: artciles_data;
      display: flex;
      max-height: 350px;
      overflow-y: hidden;
      padding: 20px;
    }
    &-speedData{
      grid-area: speed_data;
      display: flex;
      max-height: 350px;
      overflow-y: hidden;
      padding: 20px;
    }
  }
}
