@import "../utils/mixins";

.register{
  @include page;
  display: flex;
  justify-content: center;
  align-items: center;
  &-formBox{
    width: 300px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 25px;
    padding-bottom: 50px;
    border-radius: 30px;
    box-shadow: 0px 0px 17px 1px rgba(0,0,0,0.20);
  }
}
