@import "../utils/mixins";

.validation{
  @include page;
  display: flex;
  justify-content: center;
  align-items: center;


  font-size: 35px;
}
