@import "../../utils/mixins";

.headersAdmin{
  @include page;
  &-content{
    &--list{
      @include content-list-view;
      &-item{
        @include content-item;
        display: none;
      }
      &-list{
        @include content-list;
      }
    }
    &--split{
      @include content-split-view;
      &-item{
        @include content-item;
        > div{
          margin-bottom: 10px;
        }
      }
      &-list{
        @include content-list;
      }
    }
    &--item{
      @include content-item-view;
      &-item{
        @include content-item;
      }
      &-list{
        @include content-list;
        display: none;
      }
    }
  }
}
